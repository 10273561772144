import React from 'react';

function Portfolio() {
  return (
    <div className="text-white">
      {/* <h1 className="text-3xl">My Portfolio</h1> */}
    </div>
  );
}

export default Portfolio;

import React from 'react';

function Blog() {
  return (
    <div className="text-white">
      {/* <h1 className="text-3xl">My Blog</h1> */}
    </div>
  );
}

export default Blog;

import React from 'react';

function Home() {
  return (
    <div className="text-white">
      <h1 className="text-3xl text-purple-400 text-center">Jedi's still constructing this site.</h1>
    </div>
  );
}

export default Home;
